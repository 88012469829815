
import { Field, ErrorMessage } from "vee-validate";
import {
  defineComponent,
  onMounted,
  ref,
  watch,
  watchEffect,
  computed,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { ElNotification } from "element-plus/es";
import JwtService from "@/core/services/JwtService";

// import { AllBots } from "@/store/Pinia/AllBots";
// import { platforms } from "@/store/Pinia/Platforms";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-connect-robot",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    botId: {
      type: String,
      default: "",
    },
  },
  emits: ["connected"],
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const route = useRoute();
    const store = useStore();

    // const AllBotsHolder = AllBots();
    // const { findBot, activeBotId } = storeToRefs(AllBotsHolder);
    // const { fetchBotList, disconnect, setDefaultBotId } = AllBotsHolder;

    // BOT LIST
    const botList = ref();
    const fetchBotList = async () => {
      await ApiService.query("/crawler/bot", {})
        .then(({ data }) => {
          botList.value = data.data.crawler_bots.data;
          console.log(botList.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const activeBotId = ref();
    const setDefaultBotId = (bot_id) => {
      activeBotId.value = bot_id;
    };

    const findBot = computed(() => {
      if (!botList.value) return;
      return botList.value.find((item) => item.bot_id === activeBotId.value);
    });

    // PLATFORM LIST

    // const platformsHolder = platforms();
    // const { platformsList } = storeToRefs(platformsHolder);

    const platformsList = ref();
    const get_platforms = async () => {
      await ApiService.query("/crawler/platforms", {})
        .then(({ data }) => {
          platformsList.value = data.data.crawler_platforms;
          console.log(platformsList.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const activate_platform = async (botId, plat) => {
      await ApiService.query(`crawler/bot/${botId}/${plat}/activate`, {})
        .then(({ data }) => {
          platformsList.value = data.data.crawler_platforms;
          console.log(platformsList.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const formData = ref({
      mobile: "",
      email: "",
      password: "",
    });

    const code = ref();
    const authType = ref("otp");

    const selectedPlatform = ref();
    const step = ref(1);
    const disabledSend = ref(false);

    const activatePlatCount = ref(0);
    const formSubmit = () => {
      // login with otp
      if (authType.value === "otp") {
        if (!code.value || !formData.value.mobile) {
          ElNotification({
            title: "توجه",
            message: "کد تائید را وارد کنید.",
            type: "error",
          });
          return false;
        }
        disabledSend.value = true;
        ApiService.post(
          `/bot/auth-otp/${activeBotId.value}/${selectedPlatform.value.type}/login`,
          {
            mobile: formData.value.mobile,
            code: code.value,
          }
        )
          .then(() => {
            // success
            ++activatePlatCount.value;
          })
          .catch(({ response }) => {
            if (response && response.data)
              store.commit(Mutations.SET_ERROR, response.data);
          });
      }
    };

    const BtnConnectWithAuth = ref(false);
    const connectWithoutAuth = async () => {
      BtnConnectWithAuth.value = true;
      // ApiService.post(
      //   `/bot/${activeBotId.value}/${selectedPlatform.value.type}/activate`,
      //   {}
      // )

      await ApiService.post(
        `crawler/bot/${activeBotId.value}/${selectedPlatform.value.type}/activate`,
        {}
      )
        .then(() => {
          ++activatePlatCount.value;
          fetchBotList();
          ElNotification({
            title: "توجه",
            message: `پلتفرم ${translate(
              selectedPlatform.value.type
            )} نظر با موفقیت متصل شد.`,
            type: "success",
          });
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
          return false;
        })
        .finally(() => {
          BtnConnectWithAuth.value = false;
        });
    };

    const handleClick = () => {
      if (authType.value === "otp") {
        if (!formData.value.mobile) {
          ElNotification({
            title: "توجه",
            message: "شماره موبایل را وارد کنید.",
            type: "error",
          });
          return false;
        }
        // send sms
        ApiService.post(
          `/bot/auth-otp/${activeBotId.value}/${selectedPlatform.value.type}/send-code`,
          {
            mobile: formData.value.mobile,
          }
        )
          .then(() => {
            ElNotification({
              title: "توجه",
              message: "کد تائید برای شما ارسال شد.",
              type: "success",
            });
          })
          .catch(({ response }) => {
            if (response && response.data)
              store.commit(Mutations.SET_ERROR, response.data);
            return false;
          });
      }
      step.value = 2;
    };

    const checkPlatformStatus = (platformName) => {
      if (findBot.value && findBot.value.platforms) {
        let find = findBot.value?.platforms.find(
          (item, index) => item.platform === platformName
        );
        if (find) {
          return find.status;
        }
      }
      return "not_connected";
    };

    onMounted(() => {
      setDefaultBotId(route.params.bot_id ? route.params.bot_id : props.botId);
      setCurrentPageBreadcrumbs("اتصال به پلتفرم ها", ["اشتراک ها"]);

      fetchBotList();
      get_platforms();
    });

    watch(findBot.value, () => {
      if (findBot.value && findBot.value.platforms) {
        emit(
          "connected",
          findBot.value.platforms.filter((item) => item.status === "ready")
            .length
        );
      }
    });

    watchEffect(() => {
      if (platformsList.value) {
        selectedPlatform.value = route.query.platform
          ? platformsList.value[`${route.query.platform}`]
          : Object.values(platformsList.value)[0];
      }
    });

    return {
      translate,
      formData,
      authType,
      code,
      step,
      formSubmit,
      handleClick,
      selectedPlatform,
      checkPlatformStatus,
      // socketResponse,
      disabledSend,
      // disconnect,
      route,
      connectWithoutAuth,
      BtnConnectWithAuth,
      activeBotId,
      platformsList,
      findBot,
    };
  },
});
